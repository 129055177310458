import React, { Component } from 'react'

import ReportSection from './ReportSection'
import YesNoInput from './YesNoInput'

/*
Were everyone’s dietary needs met? [Y/N + appearing text box]
Was there an opportunity for everyone to eat vegetables? [Y/N]
Did each MYO meal abide by PALS MYO Food Policy? [Y/N + appearing text box]
Does anything else come to mind? [Y/N + appearing text box]
*/

class ReportSectionProvisions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleComment = (e) => {
    let target = e.target ? e.target : e
    let obj = {}
    obj[target.name] = target.value
    this.setState(obj)
  }

  render() {
    this.extraProps = {
      toggleComment: this.toggleComment,
      reportState: this.state
    }
    return (
      <ReportSection reportName='Provisions' submitColor='btn-pals-blue' {...this.props} {...this.extraProps}>
        <YesNoInput name="dietaryNeeds" commentValue="No" label="Were everyone’s dietary needs met?" {...this.extraProps}/>
        <YesNoInput name="vegetables" label="Was there an opportunity for everyone to eat vegetables?" {...this.extraProps}/>
        <YesNoInput name="myoMeals" commentValue="No" label="Did each MYO (make-your-own) meal abide by PALS MYO Food Policy?" {...this.extraProps}/>
        <YesNoInput name="anythingElse" commentValue="Yes" label="Does anything else come to mind?" {...this.extraProps}/>
      </ReportSection>
    )
  }
}

export default ReportSectionProvisions;
