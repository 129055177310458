import React, { Component } from 'react'

import spinner from '../images/spinner.svg'
import './Note.css';
import Header from './Header'

class Note extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      participant: '',
      participantList: null,
      isLoading: false,
      isLoadingFailed: false,
      isSubmitting: false,
      isSubmittingSucceeded: false,
      isSubmittingFailed: false,
    };
  }

  onFieldChange(field, e) {
    let update = {}
    update[field] = e.target.value
    this.setState(update);
  }

  maybeLoadData() {
    if (!this.state.isLoading && !this.state.isLoadingFailed && (this.props.localUser || this.props.googleUser) && !this.state.participantList) {
      let self = this
      self.setState({
        isLoading: true
      })

      let url = '/api/google/participants'

      let req = new Request(url, {
        method: 'GET',
        credentials: 'same-origin',
      });
      fetch(req)
        .then(function (response) {
          if (response.status === 401) {
            window.location.replace('/')
            return
          }
          if (!response.ok) {
            self.setState({
              isLoading: false,
              isLoadingFailed: true
            })
            return
          }
          return response.json()
        }).then(function (json) {
          self.setState({
            isLoading: false,
            participantList: json.data.participants
          })
        }).catch(function (error) {
          self.setState({
            isLoading: false,
            isLoadingFailed: true
          })
        })
    }
  }

  componentDidMount() {
    this.maybeLoadData()
  }

  componentDidUpdate() {
    this.maybeLoadData()
    if (this.messageInput) {
      this.messageInput.focus()
    }
  }

  onClickUser(name) {
    this.setState({
      participant: name,
      isSubmittingSucceeded: false
    })
  }

  submit = (e) => {
    e.preventDefault()
    let self = this
    self.setState({
      isSubmitting: true,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let noteBody = {
      message: this.state.message,
      participant: this.state.participant
    }
    let url = '/api/google/note'
    let req = new Request(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(noteBody)
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        await maybeSleep()
        if (!response.ok) {
          self.setState({
            isSubmitting: false,
            isSubmittingFailed: true
          })
          return
        }
        self.setState({
          isSubmitting: false,
          isSubmittingSucceeded: true,
          participant: '',
          message: ''
        })
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isLoggingIn: false,
          isSubmittingFailed: true
        })
      })
  }

  render() {
    const self = this
    const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ-'
    let sortedParticipantList = {}
    if (this.state.participantList) {
      this.state.participantList.forEach(function (entry) {
        let l = entry.name.charAt(0).toUpperCase()
        if (l === '*') {
          l = ' '
          entry.name = entry.name.substr(1)
        }
        if (!alphabet.includes(l)) {
          l = '-'
        }
        sortedParticipantList[l] = (sortedParticipantList[l] || []).concat([entry])
      })
    }

    let programName = ''
    if (this.props.localUser) {
      programName = this.props.localUser.fullname
    } else {
      programName = this.props.googleUser.program
    }

    return (
      <div className="note-list">
        <Header className="h3 mb-4 font-weight-normal" title="Participant Note for" subtitle={programName} />
        {this.state.isLoading &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='loading' />
            <p>Loading...</p>
          </div>
        }
        {this.state.isLoadingFailed &&
          <div>
            <p>Loading failed. Please reload this page to try again.</p>
          </div>
        }
        {this.state.isSubmitting &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='submitting' />
            <p>Submitting...</p>
          </div>
        }
        {this.state.isSubmittingFailed &&
          <div className="alert alert-danger">
            Note submission failed. Please reload this page to try again.
          </div>
        }
        {this.state.isSubmittingSucceeded &&
          <div>
            <div className="alert alert-success">
              Note submission suceeded. Thank you!
            </div>
          </div>
        }
        {
          this.state.participantList && !this.state.participant &&
          <div className="list-group">
            {
              alphabet.split('').map(function (letter, i) {
                if (!sortedParticipantList[letter]) {
                  return null
                }
                return (
                  <div key={i}>
                    <p className="list-group-item list-group-item-dark font-weight-bold">&nbsp;{letter}&nbsp;</p>
                    {
                      sortedParticipantList[letter].map(function (entry, i) {
                        return (
                          <a key={i} onClick={self.onClickUser.bind(self, entry.name)} className="list-group-item list-group-item-action">{entry.name}</a>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        }
        {
          this.state.participantList && this.state.participant &&
          !this.state.isSubmitting && !this.state.isSubmittingFailed && !this.state.isSubmittingSucceeded &&
          <div className='note-container'>
            <h2 className="h3 mb-4 font-weight-normal">{this.state.participant}</h2>
            <form onSubmit={this.submit}>
              <div className="form-group mb-0">
                <textarea
                  maxLength="500" className="form-control" id="message" rows="3"
                  value={this.state.message}
                  onChange={this.onFieldChange.bind(this, 'message')}
                  ref={(input) => { this.messageInput = input; }}
                />
              </div>
              <p className="small text-muted text-left">{500 - this.state.message.length} / 500 characters left</p>
              <button type="submit" disabled={!this.state.message} className="btn btn-lg btn-success btn-block">Submit</button>
            </form>
          </div>
        }
      </div>
    )
  }
}

export default Note;
