import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import TaskTypeTraining from './TaskTypeTraining'
import TaskTypeDeliverable from './TaskTypeDeliverable'
import TaskTypeCall from './TaskTypeCall'

import spinner from '../../images/spinner.svg'

class TaskCard extends Component {
  render() {
    let submitColor = 'btn-success'
    if (this.props.task.type === 'training') {
      if (this.props.task.optional) {
        submitColor = 'btn-pals-green'
      } else {
        submitColor = 'btn-pals-blue'
      }
    } else if (this.props.task.type === 'deliverable') {
      submitColor = 'btn-pals-orange'
    } else if (this.props.task.type === 'call') {
      submitColor = 'btn-pals-teal'
    }
    let canSubmit = ['deliverable', 'call'].includes(this.props.task.type)
    let taskProps = { ...this.props.task }
    taskProps.taskSubmissions = this.props.taskSubmissions
    taskProps.getTaskDateLabel = this.props.getTaskDateLabel
    taskProps.user = this.props.user
    return (
      <div>
        <form onSubmit={this.props.onSubmit} ref={(i) => { this.form = i }}>
          <div className="card">
            <div className="card-body">
              {/*
              change the key property every time we load new task submission state from the server
              this ensures that the components are remounted to render up-to-date submission info
            */}
              {this.props.task.type === 'training' &&
                <TaskTypeTraining {...taskProps} key={this.props.taskSubmissionReceived} />
              }
              {this.props.task.type === 'deliverable' &&
                <TaskTypeDeliverable {...taskProps} key={this.props.taskSubmissionReceived} />
              }
              {this.props.task.type === 'call' &&
                <TaskTypeCall {...taskProps} key={this.props.taskSubmissionReceived} />
              }
              {canSubmit &&
                <button type="submit" className={classNames("mt-5 btn btn-lg btn-block", submitColor)} disabled={this.props.isSubmitting}>
                  {this.props.task.type === 'deliverable' ? 'Complete' : 'Submit'}
                </button>
              }
            </div>
          </div>
        </form>
        {this.props.isSubmitting &&
          <div className="mt-4">
            <img className='spinner mb-2' src={spinner} alt='submitting' />
            <p>Submitting...</p>
          </div>
        }
        {this.props.isSubmittingFailed &&
          <div className="mt-4 alert alert-warning">
            Report submission failed. Please reload this page to try again.
          </div>
        }
        {this.props.isSubmittingSucceeded &&
          <div className="mt-4 alert alert-success">
            Report submission suceeded. Thank you!
          </div>
        }
        <div className="mt-2">
          <Link to={`/tasks/bucket/${this.props.task.bucket.name}`}>←</Link>
        </div>
      </div>
    )
  }
}

export default TaskCard;
