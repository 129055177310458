import React, { Component } from 'react'

import ReportSection from './ReportSection'
import YesNoInput from './YesNoInput'
import ScaleInput from './ScaleInput'

/*
Please rate the Counselors’/CPS’ performance today. Are any performing above or below your expectations? [1-5 scale + text box]
How well did your Campers engage today? Were there any struggles or successes? [1-5 scale + text box]
Please rate your team’s experience with today’s programming. [1-5 scale + text box]
How well were you supported by your Directors today?  [1-5 scale + text box]
Is there anything else you’d like us to know? [Text box]
*/

class ReportSectionPersonnel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleComment = (e) => {
    let target = e.target ? e.target : e
    let obj = {}
    obj[target.name] = target.value
    this.setState(obj)
  }

  render() {
    this.extraProps = {
      toggleComment: this.toggleComment,
      reportState: this.state,
      backToReport: true
    }

    return (
      <ReportSection reportName='Head Counselor Personnel' submitColor='btn-pals-orange' {...this.props} {...this.extraProps}>
        <ScaleInput name="counselorPerf" label="Please rate the Counselors’/CPS’ performance today" placeholderOverride="Are any performing above or below your expectations?" {...this.extraProps} />
        <ScaleInput name="camperEngagement" label="How well did the Campers engage today?" placeholderOverride="Were there any struggles or successes?" {...this.extraProps}/>
        <ScaleInput name="teamExperience" label="Please rate your team’s experience with today’s programming" useGoodBadScale={true} {...this.extraProps}/>
        <ScaleInput name="directorSupport" label="How well were you supported by your Directors today?" {...this.extraProps}/>
        <YesNoInput name="anythingElse" commentValue="Yes" label="Is there anything else you’d like us to know?" {...this.extraProps}/>
      </ReportSection>
    )
  }
}

export default ReportSectionPersonnel;
