import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Header from '../Header'

const moment = require('moment')


class GoogleNav extends Component {
  componentDidMount() {
    const isSuper = this.props.googleUser.staffrole === 'Administrator' || this.props.googleUser.staffrole === 'Program Staff'
    if (!isSuper) {
      return
    }
    const self = this
    let req = new Request('/api/google/lastupdate', {
      method: 'GET',
      credentials: 'same-origin'
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error("not ok")
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          lastUpdate: json['lastupdate']
        })
      }).catch(function (error) {
        return
      })
  }

  render() {
    const isAdmin = this.props.googleUser.staffrole === 'Administrator'
    const isStaff = this.props.googleUser.staffrole === 'Administrator' || this.props.googleUser.staffrole === 'Program Staff'
    const isDirector = this.props.googleUser.staffrole === 'Director' || this.props.googleUser.staffrole === 'Lead'
    const programAdminReportHref = "https://forms.gle/nPyUz8Ap4PqKk43V9"
    const teamLeadReportHref = "https://forms.gle/YZXuodetNf91RQxt8"
    const incidentFormHref = "https://forms.gle/z9gkZ2u9cHE2R3E28"
    const programScheduleHref = this.props.googleUser.programschedulelink || "https://www.palsprograms.org"
    const photoUploadHref = this.props.googleUser.photouploadlink || "https://www.palsprograms.org"
    const resourceCenterHref = "https://www.palsprograms.org/pals-leads-resource-center"
    const settingsSheetHref = "https://docs.google.com/spreadsheets/d/1gZeXpYc_jUoaOcDGSH4C8Bz977TBNXyXP1KGysecP2Q/edit#gid=0"
    const feedbackSheetHref = "https://docs.google.com/spreadsheets/d/1REtISYxkzdHGU3pLOqi-8sI3kZzYw1cik4lTbu2AR30/edit#gid=0"
    const isCampMode = true
    const hasProgram = !!this.props.googleUser.program
    return (
      <div>
        <div className="nav-container">
          <h1 className="h5 mb-4 font-weight-normal">
            <Header className="h5 mb-4" title={"Welcome, " + this.props.googleUser.givenname + "!"} subtitle={this.props.googleUser.program} />
          </h1>
          {
            isCampMode && hasProgram &&
            <React.Fragment>
              <Link to="/call" className="btn btn-lg btn-success btn-block">Program Roster</Link>
              <Link to="/support" className="btn btn-lg btn-success btn-block">Get Support</Link>
              <a href={programScheduleHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Program Schedule ➚</a>
              <a href={resourceCenterHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Leads Resources ➚</a>
              {isStaff &&
                <a href={programAdminReportHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Admin Daily Report ➚</a>
              }
              {isDirector &&
                <a href={teamLeadReportHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Lead Daily Report ➚</a>
              }
              <a href={incidentFormHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Incident Report ➚</a>
              {
                isStaff &&
                <Link to="/text" className="btn btn-lg btn-success btn-block">Send a Program Text</Link>
              }
              <Link to="/note" className="btn btn-lg btn-success btn-block">Participant Note</Link>
              <a href={photoUploadHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Photo Upload ➚</a>
              <Link to="/socials" className="btn btn-lg btn-success btn-block">PALS Socials</Link>
              <Link to="/feedback" className="btn btn-lg btn-success btn-block">Feedback</Link>
            </React.Fragment>
          }
          {
            isAdmin &&
            <>
              <hr />
              <a href={settingsSheetHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Settings Sheet ➚</a>
              <a href={feedbackSheetHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Feedback Sheet ➚</a>
              <Link to="/admin" className="btn btn-lg btn-success btn-block">Admin</Link>
            </>
          }
          {
            (this.state && this.state.lastUpdate) &&
            <div className="mt-4 text-muted">
              Last google sheets update at {moment(this.state.lastUpdate).local().format('MM/DD/YYYY h:mm:ss a')}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default GoogleNav;
