import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Header from '../Header'

class ParticipantNav extends Component {
  render() {
    const programScheduleHref = this.props.localUser.programschedulelink || "https://www.palsprograms.org"
    const photoUploadHref = this.props.localUser.photouploadlink || "https://www.palsprograms.org"
    return (
      <div className="nav-container">
        <Header className="h3 mb-4 font-weight-normal" subtitle={this.props.localUser.fullname} />
        <Link to="/call" className="btn btn-lg btn-success btn-block">Program Roster</Link>
        <Link to="/support" className="btn btn-lg btn-success btn-block">Get Support</Link>
        <a href={programScheduleHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Program Schedule ➚</a>
        <a href={photoUploadHref} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-success btn-block">Photo Upload ➚</a>
        <Link to="/socials" className="btn btn-lg btn-success btn-block">PALS Socials</Link>
        <Link to="/feedback" className="btn btn-lg btn-success btn-block">Give Feedback</Link>
      </div>
    );
  }
}

export default ParticipantNav;
