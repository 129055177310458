import React, { Component } from 'react'

import LocalLogin from './LocalLogin'
import spinner from '../images/spinner.svg'
import './Call.css';
import Header from './Header'

const moment = require('moment')
import { ColorTranslator } from 'colortranslator';

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callList: null,
      isLoading: false,
      isLoadingFailed: false
    };
  }

  maybeLoadData() {
    if (!this.state.isLoading && !this.state.isLoadingFailed && (this.props.localUser || this.props.googleUser) && !this.state.callList) {
      let self = this
      self.setState({
        isLoading: true
      })

      let url = this.props.localUser ? '/api/local/call' : '/api/google/call'

      let req = new Request(url, {
        method: 'GET',
        credentials: 'same-origin',
      });
      fetch(req)
        .then(function (response) {
          if (response.status === 401) {
            window.location.replace('/')
            return
          }
          if (!response.ok) {
            self.setState({
              isLoading: false,
              isLoadingFailed: true
            })
            return
          }
          return response.json()
        }).then(function (json) {
          self.setState({
            isLoading: false,
            callList: json.data.call,
            lastUpdate: json.data.lastupdate
          })
        }).catch(function (error) {
          self.setState({
            isLoading: false,
            isLoadingFailed: true
          })
        })
    }
  }

  componentDidMount() {
    this.maybeLoadData()
  }

  componentDidUpdate() {
    this.maybeLoadData()
  }

  render() {
    if (!this.props.localUser && !this.props.googleUser) {
      return (<LocalLogin onChangeUser={this.props.onChangeUser} />)
    }

    let sortedCallList = {}
    if (this.state.callList) {
      this.state.callList.forEach(function (entry) {
        let l = ' '
        if (entry.team) {
          l = entry.team
        }
        sortedCallList[l] = (sortedCallList[l] || []).concat([entry])
      })
    }

    let programName = ''
    if (this.props.localUser) {
      programName = this.props.localUser.fullname
    } else {
      programName = this.props.googleUser.program
    }

    const isParticipant = !!this.props.localUser

    return (
      <>
      <div className="call-list">
        <Header className="h3 mb-4 font-weight-normal" title="Call-A-PAL" subtitle={programName} />
        {this.state.isLoading &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='loading' />
            <p>Loading...</p>
          </div>
        }
        {this.state.isLoadingFailed &&
          <div>
            <p>Loading failed. Please reload this page to try again.</p>
          </div>
        }
        {
          this.state.callList &&
          <div className="list-group">
            {
              Object.keys(sortedCallList).map(function (letter, i) {
                if (!sortedCallList[letter]) {
                  return null
                }
                let color = 'lightgrey'
                try {
                  const colorText = letter.toLowerCase().trim().replace(/.* /,'')
                  color = new ColorTranslator(colorText).RGBA
                  //color = ColorTranslator.getMixRGBA(['rgba(0,255,0,1)',color])
                  color = ColorTranslator.getTints(color, 3)[1]
                } catch {
                }
                return (
                  <div key={i}>
                    <p className="list-group-item font-weight-bold" style={{backgroundColor: color}}>&nbsp;{letter}&nbsp;</p>
                    {
                      sortedCallList[letter].map(function (entry, i) {
                        const title = entry.title ? ` (${entry.title})` : ''
                        const room = entry.room && !isParticipant ? ` - ${entry.room}` : ''
                        const cls = `list-group-item list-group-item-action ${entry.phone && "font-weight-bold"}`
                        return (
                          <a key={i} href={entry.phone && ("tel:" + entry.phone)} className={cls}>{entry.name}{title}{room}</a>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      {this.state.lastUpdate &&
      <div className="mt-4 text-muted">
        Last update at {moment(this.state.lastUpdate).local().format('MM/DD/YYYY h:mm:ss a')}
      </div>
      }
    </>
    )
  }
}

export default Call;
