import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import GoogleLogin from 'react-google-login'

import './MainNav.css'
import spinner from '../../images/spinner.svg'

class MainNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      isLoginFailed: false,
      isLoginUnauthorized: false,
      isLoginSucceeded: false,
      errorCode: ''
    };
  }

  googleLoginRequested = () => {
    this.setState({
      isLoggingIn: true,
      isLoginFailed: false,
      isLoginUnauthorized: false,
    })
  }

  googleLoginFailed = (obj) => {
    this.setState({
      isLoggingIn: false,
      isLoginFailed: true,
      errorCode: obj.error
    })
  }

  googleLoginSucceeded = (obj) => {
    const self = this
    const body = {
      code: obj.code
    }

    let req = new Request('/api/google/authcode', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(body)
    });

    fetch(req).then((response) => {
      if (response.status === 403) {
        this.setState({
          isLoggingIn: false,
          isLoginUnauthorized: true
        })
        return
      }
      if (!response.ok) {
        this.setState({
          isLoggingIn: false,
          isLoginFailed: false
        })
        return
      }
      this.setState({
        isLoggingIn: false
      })
      self.props.onChangeUser()
    }).catch((e) => {
      this.setState({
        isLoggingIn: false,
        isLoginFailed: true
      })
    })
  }

  render() {
    return (
      <div className="nav-container">
        {this.state.isLoggingIn ?
          <div>
            <img className='spinner mb-2' src={spinner} alt='loading' />
            <p>Logging in...</p>
          </div>
          :
          <div>
            {this.state.isLoginFailed &&
              <div>
                <div className="mt-4 alert alert-danger">
                  Google login failed. Please try again.
                  {this.state.errorCode &&
                    <div className="small font-italic">
                      (error code: {this.state.errorCode})
                    </div>
                  }
                </div>
              </div>
            }
            {this.state.isLoginUnauthorized &&
              <div className="mt-4 alert alert-danger">
                Sorry! Your google account is not setup with PALS.
              </div>
            }
            <Link to="/participant" className="btn btn-lg btn-success btn-block">Participant</Link>
            <GoogleLogin clientId="963862018799-hsf8smp898jgckprcj9o08maqgj1ar15.apps.googleusercontent.com"
              scope="profile"
              prompt="select_account"
              responseType="code"
              onRequest={this.googleLoginRequested}
              onSuccess={this.googleLoginSucceeded}
              onFailure={this.googleLoginFailed}
              className="btn btn-lg btn-success btn-block"
              buttonText="Volunteer Leader" />
            <GoogleLogin clientId="963862018799-hsf8smp898jgckprcj9o08maqgj1ar15.apps.googleusercontent.com"
              scope="profile"
              prompt="select_account"
              responseType="code"
              onRequest={this.googleLoginRequested}
              onSuccess={this.googleLoginSucceeded}
              onFailure={this.googleLoginFailed}
              className="btn btn-lg btn-success btn-block"
              buttonText="Staff" />
            <footer>
              <p className="mt-4 text-muted">
                © {(new Date()).getFullYear()} <a className="muted-link" href="https://www.palsprograms.org">PALS Programs</a>
              </p>
            </footer>
          </div>
        }
      </div>
    );
  }
}

export default MainNav;
