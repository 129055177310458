import React, { Component } from 'react'

import MainNav from './MainNav'
import ParticipantNav from './ParticipantNav'
import GoogleNav from './GoogleNav'
import LocalLogin from '../LocalLogin'

class Home extends Component {
  render() {
    return (
      <div>
        {this.props.localUser && this.props.localUser.localrole === 'participant' &&
          <ParticipantNav localUser={this.props.localUser} />
        }
        {this.props.googleUser &&
          <GoogleNav googleUser={this.props.googleUser} />
        }
        {!this.props.localUser && !this.props.googleUser &&
          (
            this.props.forceLogin ?
              <LocalLogin onChangeUser={this.props.onChangeUser} /> :
              <MainNav onChangeUser={this.props.onChangeUser}
              />
          )
        }
      </div>
    )
  }
}

export default Home;
