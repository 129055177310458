import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Header from './Header'
import LocalLogin from './LocalLogin'
import spinner from '../images/spinner.svg'

import './Feedback.css';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      name: '',
      isSubmitting: false,
      isSubmittingSucceeded: false,
      isSubmittingFailed: false,
    };
  }

  onFieldChange(field, e) {
    let update = {}
    update[field] = e.target.value
    this.setState(update);
  }

  submit = (e) => {
    e.preventDefault()
    let self = this
    self.setState({
      isSubmitting: true,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let feedbackBody = {
      message: this.state.message,
      name: this.state.name
    }
    let url = this.props.localUser ? '/api/local/feedback' : '/api/google/feedback'
    let req = new Request(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(feedbackBody)
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        await maybeSleep()
        if (!response.ok) {
          self.setState({
            isSubmitting: false,
            isSubmittingFailed: true
          })
          return
        }
        self.setState({
          isSubmitting: false,
          isSubmittingSucceeded: true
        })
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isLoggingIn: false,
          isSubmittingFailed: true
        })
      })
  }

  render() {
    if (!this.props.localUser && !this.props.googleUser) {
      return (<LocalLogin onChangeUser={this.props.onChangeUser} />)
    }

    let programName = ''
    if (this.props.localUser) {
      programName = this.props.localUser.fullname
    } else {
      programName = this.props.googleUser.program
    }

    return (
      <div className='feedback-container'>
        <Header className="h3 mb-4 font-weight-normal" subtitle={programName} />
        {this.state.isSubmitting &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='submitting' />
            <p>Submitting...</p>
          </div>
        }
        {this.state.isSubmittingFailed &&
          <div className="alert alert-danger">
            Feedback submission failed. Please reload this page to try again.
          </div>
        }
        {this.state.isSubmittingSucceeded &&
          <div>
            <div className="alert alert-success">
              Feedback submission suceeded. Thank you!
            </div>
            <Link to='/'>Click here to return to the homepage.</Link>
          </div>
        }
        {!this.state.isSubmitting && !this.state.isSubmittingFailed && !this.state.isSubmittingSucceeded &&
          <form onSubmit={this.submit}>
            <h2 className="h5 mt-2 mb-2 font-weight-normal">Enter your feedback below</h2>
            <div className="form-group mb-0">
              <textarea maxLength="500" className="form-control" id="message" rows="3" value={this.state.message} onChange={this.onFieldChange.bind(this, 'message')} />
            </div>
            <p className="small text-muted text-left">{500 - this.state.message.length} / 500 characters left</p>
            <h2 className="h5 mt-2 mb-2 font-weight-normal">Enter your name (optional)</h2>
            <p>To provide anonymous feedback, leave this field empty</p>
            <div className="form-group">
              <input type="text" maxLength="50" className="form-control" id="name" value={this.state.name} onChange={this.onFieldChange.bind(this, 'name')} />
            </div>
            <button type="submit" disabled={!this.state.message} className="btn btn-lg btn-success btn-block">Submit</button>
          </form>
        }
      </div>
    )
  }
}

export default Feedback;
