import React, { Component } from 'react'

import ReportSection from './ReportSection'
import YesNoInput from './YesNoInput'

/*
Did you check in with medical staff today? [Y/N]
Did you meet with Media Volunteers and approve photos and video for today? [Y/N + appearing text box]
Did you meet with Directors? [Y/N]
Did you attend the Head Counselor Meeting [Y/N + appearing text box]
Did you update the budget? [Y/N]
*/

class ReportSectionProcedures extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleComment = (e) => {
    let target = e.target ? e.target : e
    let obj = {}
    obj[target.name] = target.value
    this.setState(obj)
  }

  render() {
    this.extraProps = {
      toggleComment: this.toggleComment,
      reportState: this.state
    }
    return (
      <ReportSection reportName='Procedures' submitColor='btn-pals-teal' {...this.props} {...this.extraProps}>
        <YesNoInput name="medicalCheckin" commentValue="No" label="Did you check in with medical staff today?" {...this.extraProps}/>
        <YesNoInput name="mediaCheckin" commentValue="No" label="Did you meet with Media Volunteers and approve photos and video for today?" {...this.extraProps}/>
        <YesNoInput name="directorsCheckin" commentValue="No" label="Did you meet with Directors?" {...this.extraProps}/>
        <YesNoInput name="headCounselorsCheckin" commentValue="No" label="Did you attend the Head Counselor meeting?" {...this.extraProps}/>
        <YesNoInput name="budgetUpdate" commentValue="No" label="Did you update the budget?" {...this.extraProps}/>
      </ReportSection>
    )
  }
}

export default ReportSectionProcedures;
