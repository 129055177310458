import React, { Component } from 'react'

import spinner from '../images/spinner.svg'

import './Hubs.css';

class Hubs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            hub: '',
            isSubmitting: false,
            isSubmittingSucceeded: false,
            isSubmittingFailed: false,
        };
    }

    onFieldChange(field, e) {
        let update = {}
        update[field] = e.target.value
        this.setState(update);
        this.setState({ isSubmittingSucceeded: false })
    }

    submit = (e) => {
        e.preventDefault()
        let self = this
        self.setState({
            isSubmitting: true,
            isSubmittingFailed: false,
            isSubmittingSucceeded: false,
        })
        let reqTime = Date.now()

        let maybeSleep = async function () {
            let millis = 1000 - (Date.now() - reqTime)
            if (millis > 0) {
                await new Promise(resolve => setTimeout(resolve, millis))
            }
        }
        let addressBody = {
            message: this.state.message,
        }
        let req = new Request('/api/hubs', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            credentials: 'same-origin',
            body: JSON.stringify(addressBody)
        });
        fetch(req)
            .then(async function (response) {
                if (response.status === 401) {
                    window.location.replace('/')
                    return
                }
                await maybeSleep()
                if (!response.ok) {
                    self.setState({
                        isSubmitting: false,
                        isSubmittingFailed: true
                    })
                    return
                }
                self.setState({
                    isSubmitting: false,
                    isSubmittingSucceeded: true
                })
                return response.json()
            }).then(function (json) {
                self.setState({
                    isSubmitting: false,
                    hub: json.data.hub
                })
            }).catch(async function (error) {
                await maybeSleep()
                self.setState({
                    isLoggingIn: false,
                    isSubmittingFailed: true
                })
            })
    }

    render() {
        return (
            <div className='feedback-container'>
                {this.state.isSubmitting &&
                    <div>
                        <img className='spinner mb-2' src={spinner} alt='submitting' />
                        <p>Submitting...</p>
                    </div>
                }
                {this.state.isSubmittingFailed &&
                    <div className="alert alert-danger">
                        Address submission failed. Please reload this page to try again.
                    </div>
                }
                {!this.state.isSubmitting && !this.state.isSubmittingFailed &&
                    <form onSubmit={this.submit}>
                        <h2 className="h5 mt-2 mb-2 font-weight-normal">Enter address below</h2>
                        <div className="form-group">
                            <input type="text" maxLength="100" className="form-control" id="message" rows="3" value={this.state.message} onChange={this.onFieldChange.bind(this, 'message')} />
                        </div>
                        <button type="submit" disabled={!this.state.message} className="btn btn-lg btn-success btn-block">Submit</button>
                    </form>
                }
                {this.state.isSubmittingSucceeded &&
                    <div>
                        <div className="mt-2 alert alert-success">
                            {this.state.message}<br />
                            {this.state.hub}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Hubs;
