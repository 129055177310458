import React, { Component } from 'react'

import { SocialIcon } from 'react-social-icons'

class Socials extends Component {

  render() {
    return (
        <div className="nav-container">
          <a href="https://www.facebook.com/palsprograms" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-dark btn-block"><SocialIcon network="facebook" /> Facebook </a>
          <a href="https://www.instagram.com/palsprograms/" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-dark btn-block"><SocialIcon network="instagram" /> Instagram </a>
          <a href="https://www.tiktok.com/@palsprograms" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-dark btn-block"><SocialIcon network="tiktok" /> TikTok </a>
          <a href="https://www.youtube.com/palsprograms" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-dark btn-block"><SocialIcon network="youtube" /> YouTube </a>
        </div>
    )
  }
}

export default Socials;
