import React, { Component } from 'react'

import ReportSection from './ReportSection'
import YesNoInput from './YesNoInput'
import ScaleInput from './ScaleInput'

/*
Were there any noteworthy hygiene or medical issues? (A hygiene issue is noteworthy if it’s a repeat incident or atypical in nature. A medical issue is noteworthy if it extends beyond bruises, scrapes, headaches, menstruation, etc.) [Y/N + appearing text box]
Please rate the Directors’ performance today. Are either performing above or below your expectations? [1-5 scale + text box]
Please rate the Head Counselors’/HCPS’ performance today. Are any performing above or below your expectations? [1-5 scale + text box]
Please rate the Counselors’/CPS’ performance today. Are any performing above or below your expectations? [1-5 scale + text box]
How well did the Campers engage today? Were there any struggles or successes? [1-5 scale + text box]
Please rate the photographer’s performance today. Are they performing above or below your expectations? [1-5 scale + text box]
Please rate the videographer’s performance today. Are they performing above or below your expectations? [1-5 scale + text box]
Please rate the Medical Volunteers’ performance today. Are either performing above or below your expectations? [1-5 scale + text box]
*/

class ReportSectionPersonnel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleComment = (e) => {
    let target = e.target ? e.target : e
    let obj = {}
    obj[target.name] = target.value
    this.setState(obj)
  }

  render() {
    this.extraProps = {
      toggleComment: this.toggleComment,
      reportState: this.state
    }

    return (
      <ReportSection reportName='Personnel' submitColor='btn-pals-orange' {...this.props} {...this.extraProps}>
        <YesNoInput name="medicalIssues" commentValue="Yes" label="Were there any noteworthy hygiene or medical issues?" subtext="(Repeat/atypical hygenie issues or medical issues involving a nurse)" {...this.extraProps}/>
        <ScaleInput name="directorPerf" label="Please rate the Director's performance today" {...this.extraProps}/>
        <ScaleInput name="headCounselorPerf" label="Please rate the Head Counselors’/HCPS’ performance today" {...this.extraProps}/>
        <ScaleInput name="counselorPerf" label="Please rate the Counselors’/CPS’ performance today" placeholderOverride="Any pairing switches?" {...this.extraProps}/>
        <ScaleInput name="camperEngagement" label="How well did the Campers engage today?" placeholderOverride="Any struggles, successes, or behaviors brought to the Directors?" {...this.extraProps}/>
        <ScaleInput name="photographerPerf" label="Please rate the photographer’s performance today" {...this.extraProps}/>
        <ScaleInput name="videographerPerf" label="Please rate the videographer’s performance today" {...this.extraProps}/>
        <ScaleInput name="medicalPerf" label="Please rate the Medical Volunteers’ performance today" {...this.extraProps}/>
      </ReportSection>
    )
  }
}

export default ReportSectionPersonnel;
