import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import spinner from '../images/spinner.svg'

import './Text.css';

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      selectedMode: "defaultMode",
      selectedProgram: null,
      response: '',
      contactCount: null,
      list: null,
      isLoading: false,
      isSubmitting: false,
      isSubmittingSucceeded: false,
      isSubmittingFailed: false,
    };
  }

  onFieldChange(field, e) {
    let update = {}
    update[field] = e.target.value
    this.setState(update);
  }

  componentDidMount() {
    const self = this
    let req = new Request('/api/google/text', {
      method: 'GET',
      credentials: 'same-origin'
    });
    self.setState({
      isLoading: true
    })
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          isLoading: false,
          contactCount: json.data.count,
          list: json.data.list
        })
      }).catch(function (error) {
        self.setState({
          isLoading: false,
          contactCount: 'ERR',
          list: null
        })
        return
      })
  }


  submit = (e) => {
    e.preventDefault()
    let self = this
    self.setState({
      isSubmitting: true,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let feedbackBody = {
      message: this.state.message
    }
    let url
    switch (this.state.selectedMode) {
      case "defaultMode":
        url = '/api/google/text'
        break;
      case "programMode":
        url = '/api/google/textadmin'
        feedbackBody.selectedProgram = this.state.selectedProgram
        break;
      case "numberMode":
        url = '/api/google/textadmin'
        feedbackBody.inputNumber = this.state.inputNumber
        break;
    }
    let req = new Request(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(feedbackBody)
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        await maybeSleep()
        if (!response.ok) {
          throw new Error('Unknown error')
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          isSubmitting: false,
          isSubmittingSucceeded: !!json.data.success,
          isSubmittingFailed: !json.data.success,
          response: json.data.response
        })
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isSubmitting: false,
          isSubmittingFailed: true,
          response: String(error)
        })
      })
  }

  render() {
    const isRecruiting = this.props.googleUser.program.startsWith('Recruiting')

    let contactCount = this.state.contactCount
    let fullname = this.props.googleUser.program

    if (this.state.selectedMode == "programMode") {
      for (let i = 0; i < this.state.list.length; i++) {
        const p = this.state.list[i]
        if (p.program == this.state.selectedProgram) {
          fullname = p.fullname
          contactCount = p.count
        }
      }
    } else if (this.state.selectedMode == "numberMode") {
      contactCount = this.state.inputNumber && this.state.inputNumber.length > 0 ? 1 : 0
    }

    return (
      <div className='text-container'>
        <h1 className="h3 mb-4 font-weight-normal">{this.props.googleUser.program}</h1>
        {this.state.isSubmitting &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='sending' />
            <p>Sending...</p>
          </div>
        }
        {this.state.isSubmittingFailed &&
          <div>
            <div className="alert alert-danger">
              Text submission failed.<br />{this.state.response}
            </div>
            <Link onClick={this.forceUpdate} to='/text'>Click here to reload this page</Link>
          </div>
        }
        {this.state.isSubmittingSucceeded &&
          <div>
            <div className="alert alert-success">
              Text submission suceeded.<br />{this.state.response}
            </div>
            <Link to='/'>Click here to return to the homepage</Link>
          </div>
        }
        {!this.state.isSubmitting && !this.state.isSubmittingFailed && !this.state.isSubmittingSucceeded &&
          <form onSubmit={this.submit}>
            {this.state.list && this.state.list.length > 0 &&
              <div className="mb-3">
                <h2 className="h5 mt-2 mb-2 font-weight-normal">Select a text mode</h2>
                <div className="input-group mb-3">
                  <select className="custom-select" id="modeSelect" defaultValue="defaultMode" onChange={this.onFieldChange.bind(this, 'selectedMode')}>
                    <option value="defaultMode">Text {this.props.googleUser.program}</option>
                    <option value="programMode">Select a program</option>
                    <option value="numberMode">Enter a number</option>
                  </select>
                </div>
                {this.state.selectedMode == "programMode" &&
                  <>
                    <h2 className="h5 mt-2 mb-2 font-weight-normal">Select a program</h2>
                    <div className="input-group mb-3">
                      <select className="custom-select" id="programSelect" defaultValue={this.state.list[0].program} onChange={this.onFieldChange.bind(this, 'selectedProgram')}>
                        {
                          this.state.list.map(function (x, i) {
                            return (
                              <option key={i} value={x.program}>{x.program} ({x.count} people)</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </>
                }
                {this.state.selectedMode == "numberMode" &&
                  <>
                    <h2 className="h5 mt-2 mb-2 font-weight-normal">Enter a number</h2>
                    <div className="input-group mb-3">
                      <input className="form-control" maxLength="50" onChange={this.onFieldChange.bind(this, 'inputNumber')}></input>
                    </div>
                  </>
                }
              </div>
            }
            <h2 className="h5 mt-2 mb-2 font-weight-normal">Enter your text message below</h2>
            <div className="form-group mb-0">
              <textarea maxLength="100" className="form-control" id="message" rows="3" value={this.state.message} onChange={this.onFieldChange.bind(this, 'message')} />
            </div>
            <p className="small text-muted text-left">{100 - this.state.message.length} / 100 characters left</p>
            {this.state.isLoading ?
              <div>
                <img className='spinner mb-2' src={spinner} alt='sending' />
              </div>
              :
              <div>
                {this.state.selectedMode == "numberMode" ?
                  <p>All messages will have the text <br /><strong>[PALS]</strong><br /> added to the start of the message.</p>
                  :
                  <p>All messages will have the text <br /><strong>[{fullname}]</strong><br /> added to the start of the message.</p>
                }
                <p>This message will be sent to <strong>{contactCount}</strong> people.</p>
              </div>
            }
            <button type="submit" disabled={!this.state.message} className="btn btn-lg btn-success btn-block">Send</button>
          </form>
        }
      </div>
    )
  }
}

export default Text;
