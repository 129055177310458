import React, { Component } from 'react'

class ScaleInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showComment: false
    };
  }

  render() {
    let muteText = null
    switch (this.props.reportState && this.props.reportState[this.props.name]) {
      case "1":
        muteText = (<EmojiLabel emoji='🔥' label={this.props.useGoodBadScale ? "Extremely bad" : "Far below expectations"} />)
        break;
      case "2":
        muteText = (<EmojiLabel emoji='😢' label={this.props.useGoodBadScale ? "Rough" : "Below expectations"} />)
        break;
      case "3":
        muteText = (<EmojiLabel emoji='😕' label={this.props.useGoodBadScale ? "Meh" : "Marginal"} />)
        break;
      case "4":
        muteText = (<EmojiLabel emoji='😀' label={this.props.useGoodBadScale ? "Great" : "Above expectations"} />)
        break;
      case "5":
        muteText = (<EmojiLabel emoji='🎉' label={this.props.useGoodBadScale ? "Fantastic" : "Far exeeded expectations"} />)
        break;
      default:
        muteText = null
    }

    return (
      <div>
        <div className="mt-3 form-group mb-0">
          <label><strong>{this.props.label}</strong>
          { this.props.subtext &&
            <em><br/>{this.props.subtext}</em>
          }
          </label>
        </div>
        <div className="pl-4">
          <InlineRadio value="1" {...this.props} />
          <InlineRadio value="2" {...this.props} />
          <InlineRadio value="3" {...this.props} />
          <InlineRadio value="4" {...this.props} />
          <InlineRadio value="5" {...this.props} />
          { muteText }
          { !this.props.noComment && 
            <div style={{display: this.props.reportState[this.props.name] ? "block" : "none"}}>
              <textarea className="form-control" name={this.props.name + "-comment"} id={this.props.name + "-comment"} rows="3" placeholder={this.props.placeholderOverride || "Please explain"} />
            </div>
          }
        </div>
      </div>
    )
  }
}

class EmojiLabel extends Component {
  render() {
    return (
      <div className="text-muted text-left mt-2">
        <span className="h3 mr-2">{ this.props.emoji }</span>
        { this.props.label }
      </div>
    )
  }
}

class InlineRadio extends Component {
  render() {
    return (
      <div className="form-check-inline">
        <input className="form-check-input" type="radio" name={this.props.name} id={this.props.name + this.props.value} value={this.props.value} onChange={this.props.toggleComment}/>
        <label className="form-check-label mr-1" htmlFor={this.props.name}>{this.props.value}</label>
      </div>
    )
  }
}

export default ScaleInput