import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import classNames from 'classnames'
import serialize from 'form-serialize'
import moment from 'moment'

import Header from '../Header'
import TaskCard from './TaskCard'
import TaskList from './TaskList'
import spinner from '../../images/spinner.svg'

import './Tasks.css';

class Tasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isLoadingFailed: false,
      isSubmitting: false,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
      taskSubmissions: {},
      taskSubmissionReceived: null,
    }
  }

  async componentDidMount() {
    const self = this
    // get task completion info
    let req = new Request('/api/google/directortask', {
      method: 'GET',
      credentials: 'same-origin'
    });
    try {
      let response = await fetch(req)
      if (response.status === 401) {
        window.location.replace('/')
        return
      }
      if (!response.ok) {
        throw new Error("not ok")
      }
      let json = await response.json()
      if (!json) {
        return
      }
      let processedTasks = TaskList.processTaskList(json.data.tasks)
      self.setState({
        isLoading: false,
        taskSubmissions: json.data.submission,
        taskList: processedTasks,
        taskSubmissionReceived: Date.now()
      })
    } catch (e) {
      console.log(e)
      self.setState({
        isLoading: false,
        isLoadingFailed: true
      })
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    let self = this
    const formData = serialize(e.target, { hash: true, empty: true })

    self.setState({
      isSubmitting: true,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let req = new Request('/api/google/directortask', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(formData)
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        await maybeSleep()
        if (!response.ok) {
          self.setState({
            isSubmitting: false,
            isSubmittingFailed: true
          })
          return
        }
        return response.json()
      }).then(function (json) {
        self.setState({
          isSubmitting: false,
          isSubmittingSucceeded: true,
          taskSubmissions: json.data,
          taskSubmissionReceived: Date.now()
        })
        setTimeout(function () {
          self.setState({
            isSubmittingSucceeded: false
          })
        }, 2000)
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isSubmitting: false,
          isSubmittingFailed: true
        })
        setTimeout(function () {
          self.setState({
            isSubmittingFailed: false
          })
        }, 2000)
      })
  }

  render() {
    let self = this

    if (this.state.isLoading) {
      return (
        <div className="mt-4">
          <img className='spinner mb-2' src={spinner} alt='loading' />
          <p>Loading...</p>
        </div>
      )
    }
    if (this.state.isLoadingFailed) {
      return (
        <div className="mt-4 alert alert-warning">
          Loading tasks failed. Please reload this page to try again.
        </div>
      )
    }

    let taskMap = {}
    self.state.taskList.forEach((x) => taskMap[x.id] = x)

    let taskCompletionMap = {}
    self.state.taskList.forEach(function (t) {
      switch (t.type) {
        case "training":
          taskCompletionMap[t.id] = self.state.taskSubmissions[`${t.id}-Completed`] ? 100 : 0
          break;
        case "deliverable":
          let checked = 0
          for (let j = 0; j < t.checkboxes.length; j++) {
            if (self.state.taskSubmissions[`${t.id}-${t.checkboxes[j].name}`]) {
              checked += 1
            }
          }
          if (t.checkboxes.length === 0) {
            checked = self.state.taskSubmissions[`${t.id}-Completed`] ? 1 : 0
          }
          taskCompletionMap[t.id] = 100 * (checked / t.checkboxes.length)
          break;
        case "call":
          taskCompletionMap[t.id] = self.state.taskSubmissions.hasOwnProperty(`${t.id}-Rating`) ? 100 : 0
          break;
        default:
          break;
      }
    })

    let bucketCompletionMap = {}
    Object.values(TaskList.dueBuckets).forEach(function (m) {
      let totalTaskCount = 0
      let totaltaskSubmissions = 0
      self.state.taskList.forEach(function (t) {
        if (t.bucket.name === m.name) {
          totalTaskCount += 1
          totaltaskSubmissions += taskCompletionMap[t.id]
        }
      })
      bucketCompletionMap[m.name] = 100 * (totaltaskSubmissions / (totalTaskCount * 100))
    })

    let isActiveBucket = function (b) {
      let now = moment()
      if (now.format("MMMM").toLowerCase() === b) {
        return true
      }
      if (now.format("MMMM") === 'August' && b === 'september') {
        return true
      }
      return false
    }

    let tasksByType = function (ty, b) {
      return self.state.taskList.filter(function (t) {
        if (t.bucket.name !== b) {
          return false
        }
        if (ty === 'opt_training') {
          return t.type === 'training' && t.optional
        }
        return t.type === ty && !t.optional
      })
    }
    let typeLabel = function (t, opt = false) {
      if (opt && t === 'training') {
        t = 'opt_training'
      }
      switch (t) {
        case 'training':
          return 'Trainings'
        case 'opt_training':
          return 'Optional Trainings'
        case 'call':
          return 'Calls'
        case 'deliverable':
          return 'Deliverables'
        default:
          return ''
      }
    }
    let relativeBucketLabel = function (b) {
      if (self.props.googleUser.startdate && b.offset) {
        let month = moment(self.props.googleUser.startdate).utc().add(b.offset).format("MMMM")
        return ` (${month})`
      }
    }
    let getTaskDateLabel = function (task, prefix, suffix) {
      if (task.date) {
        return `${prefix ? prefix : ''}${task.date.format('M/D')}${task.hour ? " @ " + task.hour.format('ha') + " ET" : ""}${suffix ? suffix : ''}`
      }
      if (self.props.googleUser.startdate && task.dateOffset) {
        return `${prefix ? prefix : ''}${moment(self.props.googleUser.startdate).utc().add(task.dateOffset).format("M/D")}${suffix ? suffix : ''}`
      }
      return ''
    }

    return (
      <div className='tasks-container'>
        <Router>
          <div>
            <Route exact path="/tasks" render={() =>
              <div>
                <Header className="h5 mb-4" title="My Program Lead Tasks" subtitle={this.props.googleUser.program || ":tada: PALS 2021 :tada:"} />
                {
                  Object.values(TaskList.dueBuckets).map((x, i) =>
                    <Link
                      key={i}
                      className={classNames("btn btn-block btn-lg", isActiveBucket(x.name) ? '' : 'btn-fade', bucketCompletionMap[x.name] === 100 ? 'btn-secondary' : 'btn-success')}
                      to={"/tasks/bucket/" + x.name}>
                      {
                        x.label
                      }
                      {
                        relativeBucketLabel(x)
                      }
                      {bucketCompletionMap[x.name] > 0 &&
                        <span className="badge badge-pill badge-light ml-2">
                          {bucketCompletionMap[x.name] === 100 ? ' \u2714' : '...'}
                        </span>
                      }
                    </Link>
                  )
                }
                <Link to="/tasks/alltasks" className="btn btn-block btn-lg btn-secondary mt-4">All Tasks</Link>
              </div>
            } />
            <Route exact path="/tasks/alltasks" render={() =>
              <div>
                <Header className="h5 mb-4" title="All Tasks" />
                <Link to="/tasks/alltasks/training" className="btn btn-block btn-lg btn-secondary btn-pals-blue">Trainings</Link>
                <Link to="/tasks/alltasks/deliverable" className="btn btn-block btn-lg btn-secondary btn-pals-orange">Deliverables</Link>
                <Link to="/tasks/alltasks/call" className="btn btn-block btn-lg btn-secondary btn-pals-teal">Calls</Link>
                <Link to="/tasks/alltasks/opt_training" className="btn btn-block btn-lg btn-secondary btn-pals-green">Optional Trainings</Link>
                <div className="mt-2">
                  <Link to='/tasks/'>←</Link>
                </div>
              </div>
            } />
            <Route exact path="/tasks/alltasks/:taskType" render={(props) =>
              <div>
                <Header className="h5 mb-4" title={`All ${typeLabel(props.match.params.taskType)}`} />
                {
                  Object.values(TaskList.dueBuckets).map((b, i) =>
                    <div key={i}>
                      {
                        tasksByType(props.match.params.taskType, b.name).length > 0 &&
                        <Header className="h5 mt-4 font-weight-bold text-left" title={b.label} />
                      }
                      {
                        tasksByType(props.match.params.taskType, b.name).map((x, j) =>
                          <TaskItem key={j} task={x} getTaskDateLabel={getTaskDateLabel} taskCompletion={taskCompletionMap[x.id]} />
                        )
                      }
                    </div>
                  )
                }
                <div className="mt-2">
                  <Link to='/tasks/alltasks'>←</Link>
                </div>
              </div>

            } />
            <Route exact path="/tasks/bucket/:bucket" render={(props) =>
              <div>
                <Header className="h5 mb-4" title={`${TaskList.dueBuckets[props.match.params.bucket.toUpperCase()].label} Tasks`} />
                {
                  tasksByType('training', props.match.params.bucket).length > 0 &&
                  <Header className="h5 mt-4 font-weight-bold text-left" title={typeLabel('training')} />
                }
                {
                  tasksByType('training', props.match.params.bucket).map((x, i) =>
                    <TaskItem key={i} task={x} getTaskDateLabel={getTaskDateLabel} taskCompletion={taskCompletionMap[x.id]} />
                  )
                }
                {
                  tasksByType('deliverable', props.match.params.bucket).length > 0 &&
                  <Header className="h5 mt-4 font-weight-bold text-left" title={typeLabel('deliverable')} />
                }
                {
                  tasksByType('deliverable', props.match.params.bucket).map((x, i) =>
                    <TaskItem key={i} task={x} getTaskDateLabel={getTaskDateLabel} taskCompletion={taskCompletionMap[x.id]} />
                  )
                }
                {
                  tasksByType('call', props.match.params.bucket).length > 0 &&
                  <Header className="h5 mt-4 font-weight-bold text-left" title={typeLabel('call')} />
                }
                {
                  tasksByType('call', props.match.params.bucket).map((x, i) =>
                    <TaskItem key={i} task={x} getTaskDateLabel={getTaskDateLabel} taskCompletion={taskCompletionMap[x.id]} />
                  )
                }
                {
                  tasksByType('opt_training', props.match.params.bucket).length > 0 &&
                  <Header className="h5 mt-4 font-weight-bold text-left" title={typeLabel('opt_training')} />
                }
                {
                  tasksByType('opt_training', props.match.params.bucket).map((x, i) =>
                    <TaskItem key={i} task={x} getTaskDateLabel={getTaskDateLabel} taskCompletion={taskCompletionMap[x.id]} />
                  )
                }
                <div className="mt-2">
                  <Link to={`/tasks`}>←</Link>
                </div>
              </div>
            } />
            <Route exact path="/tasks/task/:id" render={(props) =>
              <div>
                <Header className="h5 mb-1" title={`${taskMap[props.match.params.id].bucket.label} Tasks`} />
                <Header className="h5 mt-1 mb-3 font-weight-bold" title={typeLabel(taskMap[props.match.params.id].type, taskMap[props.match.params.id].optional)} />
                <TaskCard
                  task={taskMap[props.match.params.id]}
                  user={this.props.googleUser}
                  onSubmit={this.onSubmit}
                  getTaskDateLabel={getTaskDateLabel}
                  {...this.state}
                />
              </div>
            } />
          </div>
        </Router>
      </div>
    )
  }
}


class TaskItem extends Component {
  render() {
    let color = {
      training: "btn-pals-blue",
      deliverable: "btn-pals-orange",
      call: "btn-pals-teal"
    }[this.props.task.type]
    if (this.props.task.optional) {
      color = "btn-pals-green"
    }
    return (
      <Link
        className={classNames("task-btn btn btn-block btn-lg text-left mt-1 mb-1 pt-1 pb-1", this.props.taskCompletion === 100 ? 'btn-secondary btn-fade' : color)}
        to={"/tasks/task/" + this.props.task.id}>
        {`${this.props.getTaskDateLabel(this.props.task, '', ': ')}${this.props.task.name}`}
        {this.props.taskCompletion > 0 &&
          <span className="badge badge-pill badge-light ml-2">
            {this.props.taskCompletion === 100 ? ' \u2714' : '...'}
          </span>
        }
      </Link>
    )
  }
}

export default Tasks;
