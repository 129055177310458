import React, { Component } from 'react'
import { Route, Link, Switch } from 'react-router-dom'

import spinner from '../images/spinner.svg'

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingOut: false,
    };
  }

  logout = (e) => {
    e.preventDefault()
    let self = this
    self.setState({
      isLoggingOut: true
    })

    let req = new Request('/api/logout', {
      method: 'POST',
      credentials: 'same-origin',
    });

    fetch(req).then((response) => {
      self.setState({
        isLoggingOut: false
      })
      if (!response.ok) {
        return
      }
      window.location.replace('/logout')
    }).catch((e) => {
      self.setState({
        isLoggingOut: false
      })
      return
    })
  }

  render() {
    const isLoggedin = this.props.localUser || this.props.googleUser
    let roleLabel = this.props.googleUser && this.props.googleUser.staffrole
    if (roleLabel === 'Director') {
      roleLabel = 'Lead'
    }
    return (
      <footer>
        {
          this.state.isLoggingOut ?
            <img className='spinner mb-2' src={spinner} alt='loading' />
            :
            <div>
              <p className="mt-4">
                {this.props.googleUser &&
                  <span className="text-muted">{this.props.googleUser.fullname} ({roleLabel})<br /></span>
                }
                <Switch>
                  <Route exact path="/" />
                  <Route path="/logout" />
                  <Route>
                    <span>
                      <Link to="/">Home</Link>
                      {isLoggedin && <span> | </span>}
                    </span>
                  </Route>
                </Switch>
                {isLoggedin &&
                  <a href="/logout" onClick={this.logout}>Logout</a>
                }
              </p>
            </div>
        }
      </footer>
    );
  }
}

export default Footer;
