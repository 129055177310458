import React, { Component } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'

import Header from '../Header'
import ReportSectionProvisions from './ReportSectionProvisions'
import ReportSectionProgramming from './ReportSectionProgramming'
import ReportSectionPersonnel from './ReportSectionPersonnel'
import ReportSectionProcedures from './ReportSectionProcedures'
import ReportSectionCounselorPersonnel from './ReportSectionCounselorPersonnel'
import './Report.css'

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportSections: null,
    };
  }

  markReportSubmitted = (day, report) => {
    let sections = this.state.reportSections
    if (!sections) {
      sections = {}
    }
    if (!sections[day]) {
      sections[day] = []
    }
    if (!sections[day].includes(report)) {
      sections[day].push(report)
    }
    this.setState({
      reportSections: sections
    })
  }

  componentDidMount() {
    const self = this
    let urlparams = '?day=0'
    let req = new Request('/api/google/reportsections' + urlparams, {
      method: 'GET',
      credentials: 'same-origin'
    });
    fetch(req)
      .then(async function(response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error("not ok")
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          reportSections: json
        })
      }).catch(function (error) {
        return
      })
  }

  render() {
    const msDay = 60*60*24*1000
    const isHeadCounselor = this.props.googleUser.staffrole === 'Head Counselor'
    let programDays = (this.props.googleUser.enddate - this.props.googleUser.startdate) / msDay
    if (programDays < 0) {
      programDays = 0
    }
    if (programDays > 14) {
      programDays = 14
    }
    let days = []
    let dayPercents = []
    const expectedReports = isHeadCounselor ? 1 : 4
    for (let i = 0; i < programDays; i++) {
      let d = new Date(this.props.googleUser.startdate)
      days[i] = d.setUTCDate(d.getUTCDate() + i)
      if (this.state.reportSections && this.state.reportSections[i]) {
        dayPercents[i] = this.state.reportSections[i].length*100 / expectedReports
      } else {
        dayPercents[i] = 0
      }
    }

    return (
      <div className='report-container'>
        <Header className="h5 mb-4" title={(isHeadCounselor ? 'Head Counselor' : 'Staff') + ' report for'} subtitle={this.props.googleUser.program} />
        <Route exact path="/report" render={() =>
            <div>
              <h2 className="h5"><strong>Select report day</strong></h2>
              {
                [...Array(programDays)].map((x, i) =>
                  <Link
                    key={i}
                    className={classNames("btn btn-block btn-lg", dayPercents[i] === 100 ? 'btn-secondary' : 'btn-success')}
                    to={"/report/" + i}>
                      { moment(days[i]).utc().format('ddd, MMMM D YYYY') }
                      { dayPercents[i] > 0 &&
                        <span className="badge badge-pill badge-light ml-2">
                          {dayPercents[i] === 100 ? ' \u2714' : '...'}
                        </span>
                      }
                  </Link>
                )
              }
            </div>
          }/>
        <Route path="/report/:day" onChange={this.resetSubmission} render={(props) =>
          <div>
            <h2 className="h5"><strong>{moment(days[props.match.params.day]).utc().format('dddd, MMMM Do YYYY')}</strong></h2>
            <Switch>
              <Route path="/report/:day/provisions" render={() => <ReportSectionProvisions day={props.match.params.day} markReportSubmitted={this.markReportSubmitted}/>}/>
              <Route path="/report/:day/programming" render={() => <ReportSectionProgramming day={props.match.params.day} markReportSubmitted={this.markReportSubmitted}/>}/>
              <Route path="/report/:day/personnel" render={() => <ReportSectionPersonnel day={props.match.params.day} markReportSubmitted={this.markReportSubmitted}/>}/>
              <Route path="/report/:day/procedures" render={() => <ReportSectionProcedures day={props.match.params.day} markReportSubmitted={this.markReportSubmitted}/>}/>
              <Route render={(props) => (
              <div>
                {
                  isHeadCounselor ?
                    <ReportSectionCounselorPersonnel day={props.match.params.day} markReportSubmitted={this.markReportSubmitted}/>
                  :
                  <div>
                      <div>
                        <ReportLink day={props.match.params.day} reportSections={this.state.reportSections} reportName='Provisions' colorClass='btn-pals-blue' />
                        <ReportLink day={props.match.params.day} reportSections={this.state.reportSections} reportName='Programming' colorClass='btn-pals-green' />
                        <ReportLink day={props.match.params.day} reportSections={this.state.reportSections} reportName='Personnel' colorClass='btn-pals-orange' />
                        <ReportLink day={props.match.params.day} reportSections={this.state.reportSections} reportName='Procedures' colorClass='btn-pals-teal' />
                      </div>
                      <div className="mt-2">
                        <Link to={"/report"}>←</Link>
                      </div>
                  </div>
                }
              </div>
              )}/>
            </Switch>
          </div>
        }/>
      </div>
    )
  }
}

class ReportLink extends Component {
  render() {
    const reportSubmitted = this.props.reportSections && this.props.reportSections[this.props.day] && this.props.reportSections[this.props.day].includes(this.props.reportName.toLowerCase())

    return (
      <Link className={classNames("btn btn-block btn-lg", reportSubmitted ? 'btn-secondary' : this.props.colorClass)} to={"/report/" + this.props.day + "/" + this.props.reportName.toLowerCase()}>
        { this.props.reportName }
        { reportSubmitted &&
          <span className="badge badge-pill badge-light ml-2">
            {'\u2714'}
          </span>
        }
      </Link>
    )
  }
}

export default Report;
