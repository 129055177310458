const moment = require('moment')

let taskTypes = {
  TRAINING: 'training',
  DELIVERABLE: 'deliverable',
  CALL: 'call'
}

let dueBuckets = {
  //SEPTEMBER: { name: 'september', label: 'September', index: 1 },
  //OCTOBER: { name: 'october', label: 'October', index: 2 },
  //NOVEMBER: { name: 'november', label: 'November', index: 3 },
  //DECEMBER: { name: 'december', label: 'December', index: 4 },
  //JANUARY: { name: 'january', label: 'January', index: 5 },
  FEBRUARY: { name: 'february', label: 'February', index: 6 },
  MARCH: { name: 'march', label: 'March', index: 7 },
  APRIL: { name: 'april', label: 'April', index: 14 },
  MAY: { name: 'may', label: 'May', index: 15 },
  //JUNE: { name: 'june', label: 'June', index: 16},
  //THREEMONTHS: { name: 'threemonths', label: "3 Months Out", offset: {months: -3}, index: 8},
  //TWOMONTHS: { name: 'twomonths', label: "2 Months Out", offset: {months: -2}, index: 9},
  //ONEMONTH: { name: 'onemonth', label: "1 Month Out", offset: {months: -1}, index: 10},
  EIGHTWEEKS: { name: 'eightweeks', label: "8 Weeks Out", offset: { weeks: -8 }, index: 17 },
  SIXWEEKS: { name: 'sixweeks', label: "6 Weeks Out", offset: { weeks: -6 }, index: 18 },
  FOURWEEKS: { name: 'fourweeks', label: "4 Weeks Out", offset: { weeks: -4 }, index: 19 },
  TWOWEEKS: { name: 'twoweeks', label: "2 Weeks Out", offset: { weeks: -2 }, index: 11 },
  ONEWEEK: { name: 'oneweek', label: "1 Week Out", offset: { weeks: -1 }, index: 21 },
  //DURINGCAMP: { name: 'duringcamp', label: "During Camp", index: 12},
  AFTERCAMP: { name: 'aftercamp', label: "After Camp", index: 13 },
}

let exports = {
  dueBuckets: dueBuckets,
  taskTypes: taskTypes,
}

exports.processTaskList = function (tasks) {
  let toTitleCase = function (s) {
    return s.slice(0, 1).toUpperCase() + s.slice(1)
  }

  return tasks.map(function (t) {
    let ret = { ...t }
    ret.type = taskTypes[t.type.toUpperCase()]
    if (!ret.type) {
      throw new Error(`invalid type ${t.type}`)
    }
    ret.bucket = dueBuckets[t.bucket.toUpperCase()]
    if (!ret.bucket) {
      throw new Error(`invalid bucket ${t.bucket}`)
    }
    if (t.date) {
      if (t.date.startsWith("20")) {
        ret.date = moment(t.date, "YYYY-MM-DD")
      } else if (t.date.match(/.* months?/)) {
        ret.dateOffset = {
          month: Number(t.date.split(' ')[0])
        }
        delete ret.date
      } else if (t.date.match(/.* weeks?/)) {
        ret.dateOffset = {
          week: Number(t.date.split(' ')[0])
        }
        delete ret.date
      } else if (t.date.match(/.* days?/)) {
        ret.dateOffset = {
          day: Number(t.date.split(' ')[0])
        }
        delete ret.date
      } else {
        throw new Error(`invalid date ${t.date}`)
      }
    }
    if (t.hour) {
      ret.hour = moment(`${t.date}T${t.hour}`, "YYYY-MM-DDTHH")
    }
    let idPrefix = ret.bucket.index.toString().padStart(2, '0')
    let bucketLabel = toTitleCase(ret.bucket.name)
    let typeLabel = toTitleCase(ret.type)
    let cleanName = ret.name.replace(/[^0-9a-z]/gi, '')
    ret.id = `${idPrefix}-${bucketLabel}-${typeLabel}-${cleanName}`
    return ret
  })
}

export default exports
