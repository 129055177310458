import React, { Component } from 'react'
import Emoji from 'react-emoji-render'
import classNames from 'classnames'

class TaskTypeTraining extends Component {
  static displayName = "TaskTypeTraining"
  static canSubmit = false

  render() {
    let submitColor = this.props.optional ? 'btn-pals-green' : 'btn-pals-blue'
    return (
      <div>
        <div className="h5 mt-3">
          Topic: <strong>{this.props.topic}</strong>
        </div>
        <div className="h5 mt-3">
          Due Date: <em>{this.props.date ? this.props.date.format('dddd, MMMM Do') : `${this.props.dateLabel ? this.props.dateLabel : this.props.bucket.label} ${this.props.getTaskDateLabel(this.props, '(', ')')}`}</em>
        </div>
        <div className="mt-4">
          {
            this.props.taskSubmissions[`${this.props.id}-Completed`] ?
              <em><Emoji text=":raised_hands: Thanks! You completed this training! :raised_hands:" /></em>
            :
              <em>You haven't completed this training yet.</em>
          }
          
        </div>
        <div className="h5 mt-5">
          <a href={`https://palsprograms.co1.qualtrics.com/jfe/form/${this.props.surveyId}?Name=${this.props.user.fullname}&Email=${this.props.user.email}`} target="_blank" rel="noopener noreferrer" className={classNames('btn btn-lg btn-block', submitColor)}>Start Now</a>
        </div>
      </div>
    )
  }
}

export default TaskTypeTraining;
