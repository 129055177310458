import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import serialize from 'form-serialize'
import classNames from 'classnames'

import spinner from '../../images/spinner.svg'

class ReportSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isSubmittingSucceeded: false,
      isSubmittingFailed: false,
      isLoading: true,
      isLoadingFailed: false,
      isLoadingSucceeded: false
    };
  }

  componentDidMount() {
    const self = this
    this.setState({
      isLoading: true,
      isLoadingFailed: false,
      isLoadingSucceeded: false
    })
    let urlparams = '?day=' + this.props.day + '&report=' + this.props.reportName.toLowerCase().replace(/\s/g, '')
    let req = new Request('/api/google/staffreport' + urlparams, {
      method: 'GET',
      credentials: 'same-origin'
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error("not ok")
        }
        return response.json()
      }).then(function (json) {
        self.setState({
          isLoading: false,
          isLoadingSucceeded: true
        })
        if (!json) {
          return
        }
        for (let e of self.form.elements) {
          let val = json[e.name]
          if (val) {
            if (e.type === 'radio' && e.id.endsWith(val)) {
              e.checked = true
              self.props.toggleComment(e)
            }
            if (e.type === 'textarea' || e.type === 'text') {
              e.value = val
              self.props.toggleComment(e)
            }
          }
        }
      }).catch(async function (error) {
        console.log(error)
        self.setState({
          isLoading: false,
          isLoadingFailed: true
        })
      })
  }

  onSubmit = (e) => {
    e.preventDefault()
    let self = this

    const formData = serialize(e.target, { hash: true })
    formData.report = this.props.reportName.toLowerCase().replace(/\s/g, '')
    formData.day = this.props.day

    self.setState({
      isSubmitting: true,
      isSubmittingFailed: false,
      isSubmittingSucceeded: false,
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let req = new Request('/api/google/staffreport', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(formData)
    });
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        await maybeSleep()
        if (!response.ok) {
          self.setState({
            isSubmitting: false,
            isSubmittingFailed: true
          })
          return
        }
        self.props.markReportSubmitted(formData.day, formData.report)
        self.setState({
          isSubmitting: false,
          isSubmittingSucceeded: true
        })
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isSubmitting: false,
          isSubmittingFailed: true
        })
      })
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <h2 className="h5 mb-41"><strong>{this.props.reportName} Report</strong></h2>
            <form className="report-form" onSubmit={this.onSubmit} ref={(i) => { this.form = i }}>
              {this.state.isLoading &&
                <div className="mt-4">
                  <img className='spinner mb-2' src={spinner} alt='loading' />
                  <p>Loading...</p>
                </div>
              }
              {this.state.isLoadingFailed &&
                <div className="mt-4 alert alert-danger">
                  Failed to load report. Please reload this page to try again.
                </div>
              }
              {this.state.isLoadingSucceeded &&
                <div>
                  {this.props.children}
                  <button type="submit" className={classNames("mt-3 btn btn-lg btn-block", this.props.submitColor)} disabled={this.state.isSubmitting}>Submit</button>
                </div>
              }
            </form>
          </div>
        </div>
        {this.state.isSubmitting &&
          <div className="mt-4">
            <img className='spinner mb-2' src={spinner} alt='submitting' />
            <p>Submitting...</p>
          </div>
        }
        {this.state.isSubmittingFailed &&
          <div className="mt-4 alert alert-warning">
            Report submission failed. Please reload this page to try again.
          </div>
        }
        {this.state.isSubmittingSucceeded &&
          <div className="mt-4 alert alert-success">
            Report submission suceeded. Thank you!
          </div>
        }
        <div className="mt-2">
          {
            this.props.backToReport ?
              <Link to={"/report/"}>←</Link>
              :
              <Link to={"/report/" + this.props.day}>←</Link>
          }
        </div>
      </div>
    )
  }
}

export default ReportSection;
