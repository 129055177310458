import React, { Component } from 'react'
import Emoji from 'react-emoji-render'

class Header extends Component {

  render() {
    return (
      <div className={this.props.className} style={{lineHeight: "1.3em"}}>
        {this.props.title}<br/>
        <Emoji text={this.props.subtitle || ""}/>
      </div>
    )
  }
}

export default Header;
