import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom'
import cookie from 'react-cookies'

import Home from './nav/Home'
import Footer from './Footer'
import Call from './Call'
import Support from './Support'
import Rooms from './Rooms'
import Hubs from './Hubs'
import Feedback from './Feedback'
import Socials from './Socials'
import Admin from './Admin'
import Report from './report/Report'
import Note from './Note'
import Tasks from './tasks/Tasks'
import Text from './Text'
import logo from '../images/logo.png'
import './App.css'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = this.loadCookies();
  }

  loadCookies = () => {
    let localUser
    try {
      const cookieVal = cookie.load('localUser')
      if (cookieVal) {
        localUser = JSON.parse(atob(cookieVal))
      }
    } catch (e) {
      localUser = null
    }
    let googleUser
    try {
      const cookieVal = cookie.load('googleUser')
      if (cookieVal) {
        googleUser = JSON.parse(atob(cookieVal))
      }
    } catch (e) {
      googleUser = null
    }
    return {
      googleUser,
      localUser
    }
  }

  onChangeUser = () => {
    this.setState(this.loadCookies())
  }

  render() {
    let animate = !this.state.localUser && !this.state.googleUser && window.location.pathname === '/'
    const routeProps = {
      localUser: this.state.localUser,
      googleUser: this.state.googleUser,
      onChangeUser: this.onChangeUser
    }
    const self = this
    const routeGoogleUser = function (target, roles) {
      if (!self.state.googleUser) {
        return () => (<Redirect to="/" />)
      }
      if (roles && !roles.includes(self.state.googleUser.staffrole)) {
        return () => (<Redirect to="/" />)
      }
      return () => target
    }
    return (
      <Router>
        <div className='app' >
          <div className={animate ? 'img-animate' : ''}>
            <Link to="/"><img src={logo} className="mb-4" alt="logo" height="100" /></Link>
          </div>
          <div className={animate ? 'app-animate' : ''}>
            <Switch>
              <Route path="/admin" render={
                routeGoogleUser((<Admin {...routeProps} />), ['Administrator'])
              } />
              <Route path="/call" render={() => <Call {...routeProps} />} />
              <Route path="/support" render={() => <Support {...routeProps} />} />
              <Route path="/feedback" render={() => <Feedback {...routeProps} />} />
              <Route path="/socials" render={() => <Socials {...routeProps} />} />
              <Route path="/note" render={routeGoogleUser((<Note {...routeProps} />), null)} />
              <Route path="/report" render={routeGoogleUser((<Report {...routeProps} />), null)} />
              <Route path="/tasks" render={routeGoogleUser((<Tasks {...routeProps} />), ['Director'])} />
              <Route path="/text" render={
                routeGoogleUser((<Text {...routeProps} />), ['Administrator', 'Program Staff'])
              } />
              <Route path="/participant" render={() => <Home {...routeProps} forceLogin={true} />} />
              <Route path="/rooms" render={() => <Rooms {...routeProps} />} />
              <Route path="/hubs" render={() => <Hubs {...routeProps} />} />
              <Route path="/" render={() => <Home {...routeProps} />} />
            </Switch>
            <Footer {...routeProps} />
          </div>
        </div>
      </Router>
    )
  }
}

export default App;
