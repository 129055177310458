import React, { Component } from 'react'

class YesNoInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showComment: false
    };
  }

  render() {
    return (
      <div>
        <div className="mt-3 form-group mb-0">
          <label><strong>{this.props.label}</strong>
          { this.props.subtext &&
            <em><br/>{this.props.subtext}</em>
          }
          </label>
        </div>
        <div className="pl-4">
          <div className="form-check-inline">
            <input className="form-check-input" type="radio" name={this.props.name} id={this.props.name + "Yes"} value="Yes" onChange={this.props.toggleComment}/>
            <label className="form-check-label" htmlFor={this.props.name}>Yes</label>
          </div>
          <div className="form-check-inline">
            <input className="form-check-input" type="radio" name={this.props.name} id={this.props.name + "No"} value="No" onChange={this.props.toggleComment}/>
            <label className="form-check-label" htmlFor={this.props.name}>No</label>
          </div>
          { this.props.commentValue && 
            <div style={{display: this.props.reportState[this.props.name] === this.props.commentValue ? "block" : "none"}}>
              <textarea className="form-control mt-2" name={this.props.name + "-comment"} id={this.props.name + "-comment"} rows="3" placeholder={this.props.placeholderOverride || "Please explain"} />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default YesNoInput