import React, { Component } from 'react'

import spinner from '../images/spinner.svg'

class LocalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      isLoggingIn: false,
      isLoginFailed: false,
      isLoginSucceeded: false
    };
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  submit = (e) => {
    e.preventDefault()
    let self = this
    self.setState({
      isLoggingIn: true,
      isLoginFailedNetwork: false,
      isLoginFailedPassword: false,
      isLoginSucceeded: false
    })
    let reqTime = Date.now()

    let maybeSleep = async function () {
      let millis = 1000 - (Date.now() - reqTime)
      if (millis > 0) {
        await new Promise(resolve => setTimeout(resolve, millis))
      }
    }
    let loginBody = {
      username: 'palsapp',
      password: this.state.password
    }
    let req = new Request('/api/local/login', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify(loginBody)
    });
    fetch(req)
      .then(async function (response) {
        await maybeSleep()
        if (!response.ok) {
          self.setState({
            isLoggingIn: false,
            isLoginFailedPassword: true
          })
          return
        }
        self.setState({
          isLoggingIn: false
        })
        self.props.onChangeUser()
      }).catch(async function (error) {
        await maybeSleep()
        self.setState({
          isLoggingIn: false,
          isLoginFailedNetwork: true
        })
      })
  }

  render() {
    return (
      <div className="nav-container">
        <h1 className="h3 mb-4 font-weight-normal">Enter your camp password</h1>
        {this.state.isLoggingIn ?
          <div>
            <img className='spinner mb-2' src={spinner} alt='loading' />
            <p>Logging in...</p>
          </div>
          :
          <form onSubmit={this.submit}>
            {this.state.isLoginFailedNetwork &&
              <p className="text-muted">Login failed due to a network issue. Please try again.</p>
            }
            {this.state.isLoginFailedPassword &&
              <p className="text-muted">Unrecognized password. Please try again.</p>
            }
            <div className="form-group">
              <input autoFocus className="form-control" id="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
            </div>
            <button type="submit" className="btn btn-lg btn-success btn-block">Submit</button>
          </form>
        }
      </div>
    )
  }
}

export default LocalLogin;
