import React, { Component } from 'react'

import LocalLogin from './LocalLogin'
import spinner from '../images/spinner.svg'
import './Support.css';
import Header from './Header'

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staff: null,
            isLoading: false,
            isLoadingFailed: false
        };
    }

    maybeLoadData() {
        if (!this.state.isLoading && !this.state.isLoadingFailed && (this.props.localUser || this.props.googleUser) && !this.state.staff) {
            let self = this
            self.setState({
                isLoading: true
            })

            let url = this.props.localUser ? '/api/local/staff' : '/api/google/staff'

            let req = new Request(url, {
                method: 'GET',
                credentials: 'same-origin',
            });
            fetch(req)
                .then(function (response) {
                    if (response.status === 401) {
                        window.location.replace('/')
                        return
                    }
                    if (!response.ok) {
                        self.setState({
                            isLoading: false,
                            isLoadingFailed: true
                        })
                        return
                    }
                    return response.json()
                }).then(function (json) {
                    self.setState({
                        isLoading: false,
                        staff: json.data.staff
                    })
                }).catch(function (error) {
                    self.setState({
                        isLoading: false,
                        isLoadingFailed: true
                    })
                })
        }
    }

    componentDidMount() {
        this.maybeLoadData()
    }

    componentDidUpdate() {
        this.maybeLoadData()
    }

    render() {
        if (!this.props.localUser && !this.props.googleUser) {
            return (<LocalLogin onChangeUser={this.props.onChangeUser} />)
        }

        let programName = ''
        if (this.props.localUser) {
            programName = this.props.localUser.fullname
        } else {
            programName = this.props.googleUser.program
        }

        return (
            <div>
                <Header className="h3 mb-4 font-weight-normal" title="Support" subtitle={programName} />
                {this.state.isLoading &&
                    <div>
                        <img className='spinner mb-2' src={spinner} alt='loading' />
                        <p>Loading...</p>
                    </div>
                }
                {this.state.isLoadingFailed &&
                    <div>
                        <p>Loading failed. Please reload this page to try again.</p>
                    </div>
                }
                {
                    <div className="card mb-2">
                        <div className="card-body">
                            <span className="icon">🚨</span><b><em> If this is an emergency, call 911 immediately </em></b><span className="icon">🚨</span>
                        </div>
                    </div>
                }
                {
                    (this.state.staff && this.state.staff.name && this.state.staff.phone) &&
                    <div className="card mb-2">
                        <div className="card-body">
                            <span className="icon">🛟</span> Need help right now? <span className="icon">🛟</span><br />
                            <a href={"tel:" + this.state.staff.phone}>Click to call your program admin, {this.state.staff.name}</a>
                        </div>
                    </div>
                }
                <div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <span className="icon">😀</span> Want to connect? <span className="icon">😀</span><br />
                            <a href="sms:18554047257">Click to text PALS Chat</a>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <span className="icon">ℹ️</span> Looking for general information? <span className="icon">ℹ️</span><br />
                            <a href="https://www.palsprograms.org/resource-center" target="_blank" rel="noopener noreferrer">Visit the PALS Resource Center ➚</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Support;
