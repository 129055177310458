import React, { Component } from 'react'

class TaskTypeDeliverable extends Component {
  static displayName = "TaskTypeDeliverable"
  static canSubmit = true

  getFieldName(name) {
    return `${this.props.id}-${name}`
  }

  render() {
    return (
      <div>
        <div className="h5 mt-3">
          Deliverable: <strong>{this.props.name}</strong>
        </div>
        <div className="h5 mt-3">
          Due Date: <em>{this.props.date ? this.props.date.format('dddd, MMMM Do') : `${this.props.dateLabel ? this.props.dateLabel : this.props.bucket.label} ${this.props.getTaskDateLabel(this.props, '(', ')')}`}</em>
        </div>
        <div className="h5 mt-3">
          More Info: <a href={this.props.link} target="_blank" rel="noopener noreferrer">{this.props.linkTitle}</a>
        </div>
        <input
          type="hidden"
          name={this.getFieldName("Completed")}
          id={this.getFieldName("Completed")}
          value="checked"
        />
        {
          this.props.checkboxes.length > 0 &&
          <div className="h5 mt-3">
            {this.props.checkboxes[0].text}
          </div>
        }
        {/* <div className="mt-5 tasks-deliverable-box">
          {
            this.props.checkboxes.map((x, i) =>
              <div key={i} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={this.getFieldName(x.name)}
                  id={this.getFieldName(x.name)}
                  defaultChecked={this.props.taskSubmissions[this.getFieldName(x.name)]}
                />
                <label className="form-check-label" htmlFor={this.getFieldName(x.name)}>{x.text}</label>
              </div>
            )
          }
        </div> */}
      </div>
    )
  }
}

export default TaskTypeDeliverable;
