import React, { Component } from 'react'

class TaskTypeCall extends Component {
  static displayName = "TaskTypeCall"
  static canSubmit = true

  getFieldName(name) {
    return `${this.props.id}-${name}`
  }

  render() {
    return (
      <div>
        <div className="h5 mt-3 tasks-call-details">
          <strong>Topic:</strong> {this.props.name}<br/>
          <strong>Date:</strong> <em>{this.props.date.format('dddd, MMMM Do')}</em><br/>
          <strong>Time:</strong> {this.props.hour.format('ha')} ET<br/>
          <strong>Call Link:</strong> <a href="https://www.palsprograms.org/directorcalls" target="_blank" rel="noopener noreferrer">palsprograms.org/directorcalls</a><br/>
        </div>
        <div className="h5 mt-3 tasks-call-description">
          <strong>Call Description: </strong>{this.props.description}
        </div>
        <div className="h5 mt-5 tasks-call-form">
          <strong>Provide Feedback Below:</strong><br/>
          How helpful was this call?<br/><br/>
          <div className="form-check mb-2">
            <input type="radio" className="form-check-input"
              name={this.getFieldName("Rating")} id="rating1" value="1"
              defaultChecked={this.props.taskSubmissions[this.getFieldName("Rating")] === 1}
            />
            <label className="form-check-label" htmlFor="rating1">
              1 <span role="img" aria-label="fire">🔥</span> - Not helpful at all
            </label>
          </div>
          <div className="form-check mb-2">
            <input type="radio" className="form-check-input"
              name={this.getFieldName("Rating")} id="rating2" value="2"
              defaultChecked={this.props.taskSubmissions[this.getFieldName("Rating")] === 2}
            />
            <label className="form-check-label" htmlFor="rating2">
              2 <span role="img" aria-label="smirk">😕</span> - Could have been better
            </label>
          </div>
          <div className="form-check mb-2">
            <input type="radio" className="form-check-input"
              name={this.getFieldName("Rating")} id="rating3" value="3"
              defaultChecked={this.props.taskSubmissions[this.getFieldName("Rating")] === 3}
            />
            <label className="form-check-label" htmlFor="rating3">
              3 <span role="img" aria-label="party">🎉</span> - Extremely helpful!
            </label>
          </div>
          <div className="form-check mb-2">
            <input type="radio" className="form-check-input"
              name={this.getFieldName("Rating")} id="rating0" value="0"
              defaultChecked={this.props.taskSubmissions[this.getFieldName("Rating")] === 0}
            />
            <label className="form-check-label" htmlFor="rating0">
              n/a <span role="img" aria-label="fire">😭</span> I didn't attend
            </label>
          </div>
          <br/>
          <div className="form-group">
            <label htmlFor="feedback">Additional Feedback</label>
            <textarea className="form-control" name={this.getFieldName("Feedback")} id="feedback" rows="3" defaultValue={this.props.taskSubmissions[this.getFieldName("Feedback")]}></textarea>
          </div>
        </div>
      </div>
    )
  }
}

export default TaskTypeCall;
