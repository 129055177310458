import React, { Component } from 'react'

import spinner from '../images/spinner.svg'
import './Rooms.css';

class Rooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameList: null,
      isLoading: false,
      isLoadingFailed: false,
      query: ""
    };
  }

  maybeLoadData() {
    if (!this.state.isLoading && !this.state.isLoadingFailed && !this.state.nameList) {
      let self = this
      self.setState({
        isLoading: true
      })

      let url = '/api/rooms'

      let req = new Request(url, {
        method: 'GET',
        credentials: 'same-origin',
      });
      fetch(req)
        .then(function (response) {
          if (response.status === 401) {
            window.location.replace('/')
            return
          }
          if (!response.ok) {
            self.setState({
              isLoading: false,
              isLoadingFailed: true
            })
            return
          }
          return response.json()
        }).then(function (json) {
          self.setState({
            isLoading: false,
            nameList: json.data.names
          })
        }).catch(function () {
          self.setState({
            isLoading: false,
            isLoadingFailed: true
          })
        })
    }
  }

  componentDidMount() {
    this.maybeLoadData()
  }

  update = (e) => {
    this.setState({ query: e.target.value });
  }

  render() {
    let matchedNames = []
    let self = this
    if (self.state.query.length >= 3 && self.state.nameList) {
      self.state.nameList.forEach(function (n) {
        if (n.toLowerCase().includes(self.state.query.toLowerCase())) {
          matchedNames.push(n)
        }
      })
    }
    return (
      <div className="room-list">
        {this.state.isLoading &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='loading' />
            <p>Loading...</p>
          </div>
        }
        {this.state.isLoadingFailed &&
          <div>
            <p>Loading failed. Please reload this page to try again.</p>
          </div>
        }
        {
          this.state.nameList &&
          <div className="list-group">
            <input onChange={this.update} value={this.state.query} />
            {
              matchedNames.map(function (n, i) {
                return (
                  <div key={i}>
                    <p className="list-group-item align-items-left font-weight-bold">&nbsp;{n}&nbsp;</p>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    )
  }
}

export default Rooms;
