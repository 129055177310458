import React, { Component } from 'react'

import ReportSection from './ReportSection'
import YesNoInput from './YesNoInput'
import ScaleInput from './ScaleInput'

/*
On-Campus Programming [Need ability to add additional activities.]
Activity 1: 
How engaged were participants? [1-5 scale]
Is there anything you’d do differently next time? [Text box]
Off-Campus Programming [Need ability to add additional activities.]
Activity 1:
How engaged were participants? [1-5 scale]
Were headcounts performed at appropriate times and was general safety ensured? [Y/N + appearing text box]
Is there anything you’d do differently next time? [Text box]
*/

class ReportSectionProgramming extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleComment = (e) => {
    let target = e.target ? e.target : e
    let obj = {}
    obj[target.name] = target.value
    this.setState(obj)
  }

  render() {
    this.extraProps = {
      toggleComment: this.toggleComment,
      reportState: this.state
    }

    return (
      <ReportSection reportName='Programming' submitColor='btn-pals-green' {...this.props} {...this.extraProps} >
        <h5 className="mt-3">On-Campus Programming</h5>
        {
          [1,2,3,4,5].map((i) =>
            (i === 1 || this.state["onCampus" + (i-1) + "Name"]) &&
              <OnCampusSection key={i} number={i} {...this.extraProps} />
          )
        }
        <h5 className="mt-3">Off-Campus Programming</h5>
        {
          [1,2,3,4,5,6].map((x, i) =>
            (i === 1 || this.state["offCampus" + (i-1) + "Name"]) &&
              <OffCampusSection key={i} number={i} {...this.extraProps} />
          )
        }
      </ReportSection>
    )
  }
}

class OnCampusSection extends Component {
  render() {
    const formPrefix = "onCampus" + this.props.number
    const active = this.props.number === 1 || this.props.reportState[formPrefix + "Name"]
    return (
      <div className="card mb-2" style={active ? {} : {backgroundColor: "#ddd"}}>
        <div className="card-body">
          <div className="form-group mb-0">
            <label><strong>Activity name</strong></label>
          </div>
          <div className="pl-4 form-group">
              <input className="form-control" type="text" name={formPrefix + "Name"} id={formPrefix + "Name"} placeholder={"On-Campus Activity #" + this.props.number} onChange={this.props.toggleComment}/>
          </div>
          <div style={{display: active ? "block" : "none"}}>
            <ScaleInput name={formPrefix + "Engagement"} useGoodBadScale={true} label="Please rate participant engagement at this activity" {...this.props}/>
            <YesNoInput name={formPrefix + "Changes"} commentValue="Yes" label="Were there any major changes to the event?" {...this.props}/>
            <YesNoInput name={formPrefix + "Revisions"} commentValue="Yes" label="Is there anything you’d do differently next time?" {...this.props}/>
          </div>
        </div>
      </div>
    )
  }
}

class OffCampusSection extends Component {
  render() {
    const formPrefix = "offCampus" + this.props.number
    const active = this.props.number === 1 || this.props.reportState[formPrefix + "Name"]
    return (
      <div className="card mb-2" style={active ? {} : {backgroundColor: "#ddd"}}>
        <div className="card-body">
          <div className="form-group mb-0">
            <label><strong>Activity name</strong></label>
          </div>
          <div className="pl-4 form-group">
              <input className="form-control" type="text" name={formPrefix + "Name"} id={formPrefix + "Name"} placeholder={"Off-Campus Activity #" + this.props.number} onChange={this.props.toggleComment}/>
          </div>
          <div style={{display: active ? "block" : "none"}}>
            <ScaleInput name={formPrefix + "Engagement"} useGoodBadScale={true} label="Please rate participant engagement at this activity" {...this.props}/>
            <YesNoInput name={formPrefix + "Changes"} commentValue="Yes" label="Were there any major changes to the event?" {...this.props}/>
            <YesNoInput name={formPrefix + "Safety"} commentValue="No" label="Were headcounts performed and was general safety ensured?" {...this.props}/>
            <YesNoInput name={formPrefix + "Revisions"} commentValue="Yes" label="Is there anything you’d do differently next time?" {...this.props}/>
          </div>
        </div>
      </div>
    )
  }
}

export default ReportSectionProgramming
