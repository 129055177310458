import React, { Component } from 'react'

import spinner from '../images/spinner.svg'
import './Admin.css'

const moment = require('moment')

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      config: {}
    };
  }

  componentDidMount() {
    const self = this
    let req = new Request('/api/google/admin/config', {
      method: 'GET',
      credentials: 'same-origin'
    });
    self.setState({
      isLoading: true
    })
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          isLoading: false,
          config: json
        })
      }).catch(function (error) {
        self.setState({
          config: {
            error: String(error)
          },
          isLoading: false
        })
        return
      })
  }

  submit = (e) => {
    e.preventDefault()
    const self = this
    let req = new Request('/api/google/admin/refresh', {
      method: 'POST',
      credentials: 'same-origin'
    });
    self.setState({
      config: {},
      isLoading: true
    })
    fetch(req)
      .then(async function (response) {
        if (response.status === 401) {
          window.location.replace('/')
          return
        }
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      }).then(function (json) {
        if (!json) {
          return
        }
        self.setState({
          isLoading: false,
          config: json
        })
      }).catch(function (error) {
        self.setState({
          config: {
            error: String(error)
          },
          isLoading: false
        })
        return
      })
  }

  render() {
    return (
      <div>
        <h1 className="h3 mb-4 font-weight-normal">Pals App Admin</h1>
        {this.state.isLoading &&
          <div>
            <img className='spinner mb-2' src={spinner} alt='submitting' />
            <p>Loading...</p>
          </div>
        }
        <table>
          {
            Object.keys(this.state.config).map((k) => <tr><td><strong>{k}</strong></td><td>
              <p>
                {
                  Object.keys(this.state.config[k]).map((v) => <span><em>{v}: </em>
                    {
                      Number.isInteger(this.state.config[k][v]) && this.state.config[k][v] > 100000 ? moment(this.state.config[k][v]).local().format('MM/DD/YYYY h:mm:ss a') : this.state.config[k][v]
                    }
                    <br /></span>)
                }
              </p>
            </td></tr>)
          }
        </table>
        <form onSubmit={this.submit}>
          <button type="submit" className="btn btn-lg btn-success btn-block">Backend Refresh</button>
        </form>
      </div>
    )
  }
}

export default Admin;
